import React, { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import { toast } from "react-toastify";
import CustomTag from "../../../custom/CustomTag";
import Tippy from "@tippyjs/react";
import toastOptions from "../../../../assets/constants/toast";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import CustomList from "../../../custom/CustomList";
import { Copy, DownloadSimple, Eye, Rocket, TestTube } from "@phosphor-icons/react";

const TestView = () => {
    const [test, setTest] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const { testId } = useParams();
    const navigate = useNavigate();
    const [popoverVisible, setPopoverVisible] = useState(false);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);

    React.useEffect(() => {
        axios
            .get(process.env.REACT_APP_UPA_API_HOST + "tests/" + testId, {
                headers: { Authorization: "Bearer " + token },
            })
            .then((response) => {
                if (response.data) {
                    const sortedSubscriptions = response.data.subscriptions.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
                    setTest({
                        ...response.data,
                        subscriptions: sortedSubscriptions,
                    });
                    setIsLoaded(true);
                } else {
                    toast.error("Test inconnu", toastOptions);
                    navigate(-1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [navigate, token, testId]);

    const handleDelete = () => {
        if (window.confirm("Attention : toutes les données de ce test seront perdues.")) {
            axios
                .delete(process.env.REACT_APP_UPA_API_HOST + "tests/" + testId, { headers: { Authorization: "Bearer " + token } })
                .then(() => {
                    toast.success("Test supprimé", toastOptions);
                    navigate(-1);
                })
                .catch((err) => {
                    toast.error(err.response.data.message, toastOptions);
                });
        }
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Prénom",
                icon: "user",
                Cell: (row) => <Link to={row.row.original.id}>{row.row.original.firstName}</Link>,
            },
            {
                Header: "Nom",
                icon: "user",
                Cell: (row) => <Link to={row.row.original.id}>{row.row.original.lastName}</Link>,
            },
            {
                Header: "Email",
                icon: "email",
                Cell: (row) => <Link to={row.row.original.id}>{row.row.original.email}</Link>,
            },
            {
                Header: "Date du test",
                accessor: "startDate",
                icon: "calendar",
                Cell: ({ cell: { value } }) => <CustomTag value={dayjs(value).format("DD MMMM YYYY")} />,
            },
            {
                Header: "Horaire",
                icon: "conso",
                Cell: (row) => (
                    <CustomTag
                        value={dayjs(row.row.original.startDate).format("HH:mm") + " à " + dayjs(row.row.original.startDate).add(test.hours, "hours").add(test.minutes, "minutes").format("HH:mm")}
                    />
                ),
            },
            {
                Header: "Dépôt",
                accessor: "submittedAt",
                icon: "document",
                Cell: (row) => (
                    <div className="test-list-download">
                        <CustomTag
                            value={row.row.original.submittedAt ? "Déposé à " + dayjs(row.row.original.submittedAt).subtract(1, "hour").format("HH:mm:ss") : "Non déposé"}
                            className={row.row.original.submittedAt ? "true" : "false"}
                        />
                        {row.row.original.submittedAt ? (
                            <a
                                href={process.env.REACT_APP_UPA_API_HOST + "tests-uploads/" + row.row.original.id + ".zip"}
                                onClick={() => {
                                    toast.success("Fichier téléchargé avec succès", toastOptions);
                                }}
                            >
                                <DownloadSimple size={16} weight="bold" />
                            </a>
                        ) : null}
                    </div>
                ),
            },
        ],
        [test]
    );

    const popover = (
        <div className="user-options" onClick={popoverVisible ? hide : show}>
            <Link to="edit">
                <span className="user-options-item">Modifier</span>
            </Link>
            <div className="user-options-item delete" onClick={handleDelete}>
                <span>Supprimer</span>
            </div>
        </div>
    );

    return isLoaded ? (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title none">
                    <div className="icon">
                        <TestTube size={16} weight="bold" />
                    </div>
                    <h1>{test.name}</h1>
                </div>
                <div className="view-buttons">
                    {test.status === "archived" ? null : (
                        <div>
                            <a href={process.env.REACT_APP_UPA_INTERFACE_HOST + "tests/" + test.id} target="_blank" rel="noreferrer">
                                <button className="main-button blue-button">
                                    <Rocket size={16} weight="bold" />
                                    Page d'inscription
                                </button>
                            </a>
                        </div>
                    )}
                    <Tippy content={popover} allowHTML={true} placement={"bottom-end"} offset={[0, 5]} visible={popoverVisible} onClickOutside={hide} interactive={true} appendTo={"parent"}>
                        <button className="squared-button" onClick={popoverVisible ? hide : show} />
                    </Tippy>
                </div>
            </div>
            <div className="input-row-container">
                <span className="input-label link">
                    Lien du test <span className="required">*</span>
                </span>
                <CustomTag value={process.env.REACT_APP_UPA_INTERFACE_HOST + "tests/" + test.id} />
                <Copy
                    className="icon-button"
                    size={16}
                    weight="bold"
                    onClick={() => {
                        navigator.clipboard.writeText(process.env.REACT_APP_UPA_INTERFACE_HOST + "tests/" + test.id);
                        toast.success("Lien copié", toastOptions);
                    }}
                />
            </div>
            <div className="input-row-container">
                <span className="input-label calendar">
                    Dates de validité <span className="required">*</span>
                </span>
                <CustomTag value={dayjs(test.startDate).format("DD MMMM YYYY")} />
                <span className="margin-right margin-left">au</span>
                <CustomTag value={dayjs(test.stopDate).format("DD MMMM YYYY")} />
            </div>
            <div className="input-row-container">
                <span className="input-label time">
                    Durée <span className="required">*</span>
                </span>
                {test.hours ? (
                    <div className="flex-center">
                        <CustomTag value={test.hours} />
                        <span className="margin-right margin-left">heure(s)</span>
                    </div>
                ) : null}
                {test.minutes ? (
                    <div className="flex-center">
                        <CustomTag value={test.minutes} className="margin-right" />
                        <span>minute(s)</span>
                    </div>
                ) : null}
            </div>
            <div className="input-row-container">
                <span className="input-label doc">
                    Sujet <span className="required">*</span>
                </span>
                <div className="input-date-container without-icon">
                    <a href={test.subjectLink} target="_blank" rel="noreferrer" className="main-button small-button blue-button without-icon">
                        <Eye size={16} weight="bold" />
                        Voir le sujet
                    </a>
                </div>
            </div>
            {test.subscriptions.length > 0 ? (
                <div className="tabs-container">
                    <CustomList entity={test.subscriptions} columns={columns} isSelectable={false} isClickable={false} isPaginable={false} isFetchable={false} />
                </div>
            ) : (
                <div className="input-row-container">
                    <span className="input-label user">Candidats</span>
                    <CustomTag value="Ce test ne possède aucun candidat pour le moment." />
                </div>
            )}
        </div>
    ) : (
        <div className="loader-container absolute">
            <CircularProgress size={20} className="white-loader" />
        </div>
    );
};

export default TestView;
