import CustomTag from "../../custom/CustomTag";
import CustomSearchInput from "../../custom/CustomSearchInput";
import TaskOption from "./TaskOption";
import { IconButton, TextField, CircularProgress } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import AuthContext from "../../../contexts/AuthContext";
import { Lightning, Plus, CaretDown, CaretUp, Info } from "@phosphor-icons/react";
import Tooltip from "@mui/material/Tooltip";
import ModelsButton from "./ModelsButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import frLocale from "date-fns/locale/fr";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
dayjs.extend(isoWeek);

const TimeTable = ({
    entries,
    loading,
    setEntries,
    projects,
    tasks,
    sections,
    handleAddEntry,
    tab,
    automations,
    setAutomations,
    models,
    setModels,
    filterProject,
    filterDate,
    activeDay
}) => {
    const { token } = useContext(AuthContext);
    const [sortConfig, setSortConfig] = useState({ key: "date", direction: "desc" });

    if (loading) {
        return (
            <div className="loader-container absolute">
                <CircularProgress size={30} />
            </div>
        );
    }

    const handleKeyDown = (e, entry) => {
        if (e.target.classList.contains("number-input") && (e.key === "ArrowUp" || e.key === "ArrowDown")) {
            e.preventDefault();
            let currentValue = entry.duration;
            let normalized = typeof currentValue === "string" ? currentValue.replace(",", ".") : currentValue;
            let parsed = parseFloat(normalized);
            if (isNaN(parsed)) parsed = 0;
            if (e.key === "ArrowUp") {
                parsed += 0.25;
            } else {
                parsed -= 0.25;
                if (parsed < 0) parsed = 0;
            }
            handleEditEntry(entry.tempId ? entry.tempId : entry.id, { ...entry, duration: parsed });
            return;
        }
        
        if (e.key === "Enter") {
            e.preventDefault();
            handleSubmitEntry(entry);
        } else if (e.key === "Escape") {
            e.preventDefault();
            handleCancelEdit(entry.id);
        }
    };

    const requestSort = (key) => {
        if (sortConfig.key === key) {
            setSortConfig({ key, direction: sortConfig.direction === "asc" ? "desc" : "asc" });
        } else {
            setSortConfig({ key, direction: "asc" });
        }
    };

    const getEntriesFiltered = () => {
        let filtered = entries.filter(e => tab === 0 ? e.date === activeDay.format("YYYY-MM-DD") : e);
        if (filterProject) filtered = filtered.filter((entry) => entry.projectId === filterProject.id);
        if (filterDate) filtered = filtered.filter((entry) => dayjs(entry.date).isSame(dayjs(filterDate), "day"));
        if (sortConfig.key !== null) {
            filtered.sort((a, b) => {
                if (sortConfig.key === "date") {
                    const aDate = dayjs(a.date).valueOf();
                    const bDate = dayjs(b.date).valueOf();
                    if (aDate !== bDate) return sortConfig.direction === "asc" ? aDate - bDate : bDate - aDate;
                    if (a.createdAt && b.createdAt) {
                        const aCreated = dayjs(a.createdAt).valueOf();
                        const bCreated = dayjs(b.createdAt).valueOf();
                        return sortConfig.direction === "asc" ? aCreated - bCreated : bCreated - aCreated;
                    }
                    const aTie = Number(a.tempId || a.id);
                    const bTie = Number(b.tempId || b.id);
                    return sortConfig.direction === "asc" ? aTie - bTie : bTie - aTie;
                }
                let aValue, bValue;
                switch (sortConfig.key) {
                    case "project": {
                        const projectA = projects.find((p) => p.id === a.projectId);
                        const projectB = projects.find((p) => p.id === b.projectId);
                        aValue = projectA ? projectA.name.toLowerCase() : "";
                        bValue = projectB ? projectB.name.toLowerCase() : "";
                        break;
                    }
                    case "task":
                        aValue = a.task.name.toLowerCase();
                        bValue = b.task.name.toLowerCase();
                        break;
                    case "comments":
                        aValue = a.comments.toLowerCase();
                        bValue = b.comments.toLowerCase();
                        break;
                    case "duration":
                        aValue = a.duration;
                        bValue = b.duration;
                        break;
                    default:
                        return 0;
                }
                if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
                if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
                return 0;
            });
        }
        return filtered;
    };

    const handleActivateEditing = (id) => {
        setEntries(
            entries.map((entry) =>
                entry.id === id ? { ...entry, isEditing: true, originalEntry: { ...entry } } : entry.originalEntry ? { ...entry.originalEntry, isEditing: false } : { ...entry, isEditing: false }
            )
        );
    };

    const handleEditEntry = (id, updatedEntry) => {
        setEntries(entries.map((entry) => ((entry.tempId ? entry.tempId : entry.id) === id ? updatedEntry : entry)));
    };

    const handleCancelEdit = (id) => {
        setEntries(entries.map((entry) => ((entry.tempId ? entry.tempId : entry.id) === id ? { ...entry.originalEntry, isEditing: false } : entry)));
    };

    const handleDeleteEntry = (entry) => {
        if (entry.tempId) {
            setEntries(entries.filter((e) => e.tempId !== entry.tempId));
        } else {
            axios
                .delete(process.env.REACT_APP_UPA_API_HOST + `tracking/entries/${entry.id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(() => setEntries(entries.filter((e) => e.id !== entry.id)))
                .catch((error) => {
                    toast.error("Une erreur est survenue", toastOptions);
                    console.error("Error deleting entry", error);
                });
        }
    };

    const handleSubmitEntry = (entry) => {
        const durationValue = typeof entry.duration === "string" ? parseFloat(entry.duration.replace(",", ".")) : entry.duration;
        if (entry.projectId && entry.taskId && durationValue > 0) {
            const payload = { ...entry, duration: durationValue };
            if (entry.tempId) {
                axios
                    .post(process.env.REACT_APP_UPA_API_HOST + "tracking/entries", payload, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((response) => {
                        setEntries(entries.map((e) => (e.tempId === entry.tempId ? { ...response.data, projectId: response.data.task.section.projectId } : e)));
                    })
                    .catch((error) => {
                        toast.error("Une erreur est survenue", toastOptions);
                        console.error("Error saving entry", error);
                    });
            } else {
                axios
                    .put(process.env.REACT_APP_UPA_API_HOST + `tracking/entries/${entry.id}`, payload, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((response) => {
                        setEntries(entries.map((e) => (e.id === entry.id ? { ...response.data, projectId: response.data.task.section.projectId } : e)));
                    })
                    .catch((error) => {
                        toast.error("Une erreur est survenue", toastOptions);
                        console.error("Error saving entry", error);
                    });
            }
        } else {
            toast.error("Merci de remplir tous les champs", toastOptions);
        }
    };

    const taskFilter = (option, inputValue) => {
        if (!inputValue) return true;
        
        const normalize = (str) => {
            if (!str) return "";
            const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            return withoutAccents.replace(/[-_.,;:]/g, " ");
        };
        
        const searchValue = normalize(inputValue);
        const task = option.data;
        const section = sections.find((s) => s.id === task.sectionId);
        const parent = task.parentId ? tasks.find((t) => t.id === task.parentId) : null;
        
        if (normalize(task.name).includes(searchValue)) return true;
        if (section && normalize(section.name).includes(searchValue)) return true;
        return !!(parent && normalize(parent.name).includes(searchValue));
    };

    return (
        <div>
            <div className="custom-tracking-table-container">
                <table className="custom-tracking-table">
                    <thead>
                        <tr>
                            <th className="date" onClick={() => requestSort("date")}>
                                Date {sortConfig.key === "date" && (sortConfig.direction === "asc" ? <CaretUp size={12} /> : <CaretDown size={12} />)}
                            </th>
                            <th className="project" onClick={() => requestSort("project")}>
                                Projet {sortConfig.key === "project" && (sortConfig.direction === "asc" ? <CaretUp size={12} /> : <CaretDown size={12} />)}
                            </th>
                            <th className="task" onClick={() => requestSort("task")}>
                                Tâche {sortConfig.key === "task" && (sortConfig.direction === "asc" ? <CaretUp size={12} /> : <CaretDown size={12} />)}
                            </th>
                            <th className="comments" onClick={() => requestSort("comments")}>
                                Commentaires {sortConfig.key === "comments" && (sortConfig.direction === "asc" ? <CaretUp size={12} /> : <CaretDown size={12} />)}
                            </th>
                            <th className="duration" onClick={() => requestSort("duration")}>
                                Durée {sortConfig.key === "duration" && (sortConfig.direction === "asc" ? <CaretUp size={12} /> : <CaretDown size={12} />)}
                            </th>
                            <th className="actions">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getEntriesFiltered().map((entry) => (
                            <tr key={entry.id || entry.tempId}>
                                {tab === 1 ? (
                                    <td
                                        className="entry-line-date"
                                        onClick={() => {
                                            if (!entry.isEditing) handleActivateEditing(entry.id);
                                        }}
                                    >
                                        {entry.tempId || entry.isEditing ? (
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={frLocale}>
                                                <DatePicker
                                                    value={dayjs(entry.date)}
                                                    onChange={(newValue) => handleEditEntry(entry.tempId ? entry.tempId : entry.id, { ...entry, date: newValue.format("YYYY-MM-DD") })}
                                                    renderInput={(props) => <TextField {...props} onKeyDown={(e) => handleKeyDown(e, entry)} />}
                                                    inputFormat="DD/MM/YYYY"
                                                    disableFuture={false}
                                                    disablePast={false}
                                                    className="w-full"
                                                    slotProps={{
                                                        textField: {
                                                            placeholder: "",
                                                            sx: {
                                                                "& .MuiInputBase-root": { height: "40px" },
                                                                "& .MuiOutlinedInput-root": { height: "40px" },
                                                                "& .MuiInputLabel-root": {
                                                                    transform: "translate(14px, 8px) scale(1)",
                                                                    "&.MuiInputLabel-shrink": { transform: "translate(14px, -9px) scale(0.75)" },
                                                                },
                                                                "& .MuiInputBase-input": { cursor: "pointer" },
                                                            },
                                                            onKeyDown: (e) => handleKeyDown(e, entry),
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        ) : (
                                            <CustomTag value={dayjs(entry.date).format("DD/MM/YYYY")} />
                                        )}
                                    </td>
                                ) : (
                                    <td className="entry-line-date" onClick={() => handleActivateEditing(entry.id)}>
                                        <CustomTag value={dayjs(entry.date).format("DD/MM/YYYY")} />
                                    </td>
                                )}
                                {entry.tempId || entry.isEditing ? (
                                    <>
                                        <td className="entry-line-project">
                                            <CustomSearchInput
                                                isClearable
                                                entity={projects}
                                                labelType="project"
                                                value={projects.find((p) => p.id === entry.projectId)}
                                                onChange={(value) => handleEditEntry(entry.tempId ? entry.tempId : entry.id, { ...entry, projectId: value ? value.id : null, taskId: null })}
                                                placeholder="Rechercher"
                                                onKeyDown={(e) => handleKeyDown(e, entry)}
                                            />
                                        </td>
                                        <td className="entry-line-task">
                                            <CustomSearchInput
                                                entity={entry.projectId ? tasks.filter((t) => sections.some((s) => s.projectId === entry.projectId && s.id === t.sectionId)) : []}
                                                value={entry.taskId ? tasks.find((t) => t.id === entry.taskId) : null}
                                                onChange={(value) => handleEditEntry(entry.tempId ? entry.tempId : entry.id, { ...entry, taskId: value ? value.id : null })}
                                                placeholder="Rechercher"
                                                isClearable
                                                Option={(props) => <TaskOption {...props} tasks={tasks} sections={sections} />}
                                                filterOption={taskFilter}
                                                onKeyDown={(e) => handleKeyDown(e, entry)}
                                            />
                                        </td>
                                        <td className="entry-line-comments">
                                            <input
                                                type="text"
                                                className="classic-input comment-input"
                                                value={entry.comments}
                                                onChange={(event) => handleEditEntry(entry.tempId ? entry.tempId : entry.id, { ...entry, comments: event.target.value })}
                                                onKeyDown={(e) => handleKeyDown(e, entry)}
                                            />
                                        </td>
                                        <td className="entry-line-duration">
                                            <input
                                                type="text"
                                                className="classic-input number-input"
                                                value={entry.duration}
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
                                                        if (value.endsWith(".") || value.endsWith(",")) {
                                                            handleEditEntry(entry.tempId ? entry.tempId : entry.id, { ...entry, duration: value });
                                                        } else {
                                                            const normalized = value.replace(",", ".");
                                                            const parsed = parseFloat(normalized);
                                                            handleEditEntry(entry.tempId ? entry.tempId : entry.id, {
                                                                ...entry,
                                                                duration: value === "" ? "" : !isNaN(parsed) ? parsed : value,
                                                            });
                                                        }
                                                    }
                                                }}
                                                onKeyDown={(e) => handleKeyDown(e, entry)}
                                            />
                                        </td>
                                        <td className="entry-line-actions">
                                            <IconButton aria-label="done" size="small" onClick={() => handleSubmitEntry(entry)}>
                                                <DoneIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton
                                                aria-label="cancel"
                                                size="small"
                                                onClick={() => {
                                                    if (entry.tempId) {
                                                        handleDeleteEntry(entry);
                                                    } else {
                                                        handleCancelEdit(entry.id);
                                                    }
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td className="entry-line-project" onClick={() => handleActivateEditing(entry.id)}>
                                            <span>{projects.find((p) => p.id === entry.task?.section?.projectId)?.name || ""}</span>
                                        </td>
                                        <td className="entry-line-task" onClick={() => handleActivateEditing(entry.id)}>
                                            <CustomTag value={entry.task?.name || ""} />
                                        </td>
                                        <td className="entry-line-comments" onClick={() => handleActivateEditing(entry.id)}>
                                            <span className="comments">{entry.comments}</span>
                                        </td>
                                        <td className="entry-line-duration" onClick={() => handleActivateEditing(entry.id)}>
                                            <CustomTag
                                                value={
                                                    typeof entry.duration === "number" ? (Number.isInteger(entry.duration) ? entry.duration + " h" : entry.duration.toFixed(2) + " h") : entry.duration
                                                }
                                            />
                                        </td>
                                        <td className="entry-line-actions">
                                            <IconButton aria-label="edit" size="small" onClick={() => handleActivateEditing(entry.id)}>
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="delete" size="small" onClick={() => handleDeleteEntry(entry)}>
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))}
                        {tab === 0 &&
                            automations
                                .filter(
                                    (a) =>
                                        a.isActive &&
                                        activeDay.isAfter(dayjs()) &&
                                        a.repeatEvery[activeDay.isoWeekday() - 1] === 1
                                )
                                .map((a, index) => (
                                    <tr key={`automation-${index}`} className="automation-generated-line">
                                        <td className="entry-line-date">
                                            <CustomTag value={activeDay.format("DD/MM/YYYY")} />
                                        </td>
                                        <td className="entry-line-project">
                                            <span>{projects.find((p) => p.id === tasks.find((t) => t.id === a.model.taskId)?.section?.projectId)?.name || ""}</span>
                                        </td>
                                        <td className="entry-line-task">
                                            <CustomTag value={tasks.find((t) => t.id === a.model.taskId)?.name || ""} />
                                        </td>
                                        <td className="entry-line-comments">
                                            <span className="comments">{a.model.comments}</span>
                                        </td>
                                        <td className="entry-line-duration">
                                            <CustomTag value={a.model.duration + " h"} />
                                        </td>
                                        <td className="entry-line-actions">
                                            <IconButton aria-label="automation" size="small">
                                                <Lightning size={18} color="#3b61e1" weight="fill" />
                                            </IconButton>
                                            <Tooltip placement="left" title="Cette tâche sera créée automatiquement">
                                                <IconButton aria-label="info" size="small">
                                                    <Info size={18} color="#7a7a7a" weight="bold" />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                ))}
                        <tr>
                            <td colSpan={tab === 1 ? 5 : 4}>
                                <div className="new-entry">
                                    <button className="main-button new-line-button add" onClick={handleAddEntry}>
                                        <Plus size={16} color="#3b61e1" weight="bold" />
                                        Nouvelle saisie
                                    </button>
                                    <ModelsButton
                                        models={models}
                                        setModels={setModels}
                                        projects={projects}
                                        sections={sections}
                                        tasks={tasks}
                                        entries={entries}
                                        setEntries={setEntries}
                                        automations={automations}
                                        setAutomations={setAutomations}
                                        activeDay={activeDay}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr className="entries-totals">
                            <td colSpan={tab === 1 ? 5 : 4} className="entries-count">
                                {getEntriesFiltered().length} tâche(s)
                            </td>
                            <td colSpan={tab === 1 ? 1 : 2}>
                                {getEntriesFiltered()
                                    .reduce((sum, entry) => sum + (entry.tempId ? 0 : typeof entry.duration === "number" ? entry.duration : parseFloat(entry.duration) || 0), 0)
                                    .toFixed(2)}{" "}
                                h
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TimeTable;
