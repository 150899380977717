import React, { useState, useEffect } from "react";
import CustomTag from "../../../custom/CustomTag";
import CustomList from "../../../custom/CustomList";
import dayjs from "dayjs";
import { Tab, Tabs } from "@mui/material";

const ContactSupport = ({ customer }) => {

    const [tab, setTab] = useState(0);

    useEffect(() => {
        const years = Object.keys(customer.tickets).reverse();
        const index = years.indexOf(String(dayjs().year()));
        if (index !== -1) {
            setTab(index);
        }
    }, [customer.tickets]);

    const ticketsCols = React.useMemo(
        () => [
            {
                Header: 'Objet',
                icon: 'objet',
                downloadAccessor: 'subject',
                Cell: row => <a href={"https://desk.zoho.eu/agent/alteliscompany/altelis/tickets/details/" + row.row.original.id} rel="noreferrer" target="_blank">{row.row.original.subject}</a>
            },
            {
                Header: 'Temps (en h.)',
                icon: 'conso',
                accessor: 'timeEntries',
                Cell: ({ cell: { value } }) => <span>{value.reduce((total, entry) => total + entry.hoursConsumed, 0).toFixed(1)} h</span>
            },
            {
                Header: 'Statut',
                icon: 'conso',
                accessor: 'status',
                Cell: ({ cell: { value } }) => <CustomTag value={value === 'On Hold' ? 'Fait' : 'Fermé'} className={value === 'On Hold' ? 'waiting' : 'success'} />
            },
            {
                Header: 'Fermé le',
                accessor: 'closedTime',
                icon: 'calendar',
                Cell: ({ cell: { value } }) => <span>{value ? dayjs(value).format('DD/MM/YYYY') : ''}</span>
            },
            {
                Header: 'URL Desk',
                accessor: 'data.webUrl',
                id: 'desk_url'
            }
        ],
        []
    );

    return (
        <div className='tab-container'>
            {Object.keys(customer.tickets).length > 0 ? (
                <div className='tabs-container'>
                    <div className="tabs-wrapper">
                        <Tabs value={tab} onChange={(event, value) => setTab(value)} >
                            {Object.keys(customer.tickets).reverse().map((year, index) => (
                                <Tab value={index} key={index} label={year + ' (' + customer.tickets[year].length + ')'} />
                            ))}
                        </Tabs>
                    </div>
                    {Object.values(customer.tickets).reverse().map((yearTickets, index) => (
                        <div key={index} role="tabpanel" hidden={tab !== index}>
                            <CustomList entity={yearTickets} columns={ticketsCols} hiddenColumns={['desk_url']} isSelectable={false} isFetchable={false} isClickable={false} isDownloadable limit={20} />
                        </div>
                    ))}
                </div>
            ) : (
                <p className='waiting-order-warning'>Ce client ne possède aucun ticket pour le moment.</p>
            )}

        </div>
    );
}

export default ContactSupport;