import React, { useContext, useState, useEffect, useCallback } from "react";
import axios from "axios";
import constants from "../../assets/constants/constants";
import AuthContext from "../../contexts/AuthContext";
import Select from "react-select";
import CustomOption from "./CustomOption";

const normalizeString = (str) => {
    if (!str) return "";
    const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    return withoutAccents.replace(/[-_.,;:]/g, " ");
};

const CustomSearchInput = ({
                               placeholder,
                               entity,
                               isMultiSearch,
                               isClearable,
                               labelType,
                               onChange,
                               className,
                               value,
                               Option,
                               filterOption,
                               onKeyDown
                           }) => {
    const [items, setItems] = useState();
    const { token } = useContext(AuthContext);
    
    const handleKeyDown = (event) => {
        if (onKeyDown) {
            onKeyDown(event);
        }
    };

    const formatItems = useCallback(
        (items) => {
            return items.map((item) => {
                item.value = item.id;
                switch (labelType) {
                    case "user":
                        item.value = item.id ? item.id : item.lastName;
                        item.label = item.firstName + " " + item.lastName;
                        break;
                    case "address":
                        item.label = item.address;
                        item.value = item.id;
                        break;
                    case "tax":
                        item.label = item.tax_name;
                        item.value = item.tax_id;
                        break;
                    case "contact_person":
                        item.id = item.contact_person_id;
                        item.key = item.contact_person_id;
                        item.label = item.email;
                        item.value = item.contact_person_id;
                        break;
                    case "invoice":
                        item.isInvoice = true;
                        item.label = item.data.invoice_number;
                        item.value = item.id;
                        break;
                    case "engineGroup":
                        item.label = "Groupe " + item.id + " (" + item.dedgeId + " - " + item.consumerKey + ")";
                        item.value = item.id;
                        break;
                    case "constant":
                        break;
                    case "language":
                        item.label = `${item.language} (${item.code})`;
                        break;
                    case "filter":
                        const foundConstant = constants.all.find((e) => {
                            return e.value === item.label;
                        });
                        item.label = foundConstant ? foundConstant.label : item.label;
                        break;
                    default:
                        item.label = item.name;
                        break;
                }
                return item;
            });
        },
        [labelType]
    );

    useEffect(() => {
        if (typeof entity === "string") {
            axios
                .get(process.env.REACT_APP_UPA_API_HOST + entity, {
                    headers: { Authorization: "Bearer " + token },
                })
                .then((response) => {
                    setItems(formatItems(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setItems(formatItems(entity));
        }
    }, [entity, token, value, formatItems]);

    const defaultFilterOption = (option, inputValue) => {
        const optionLabel = normalizeString(option.label || "");
        const input = normalizeString(inputValue || "");
        return optionLabel.includes(input);
    };

    return (
        <Select
            isMulti={isMultiSearch}
            name="colors"
            isRequired={true}
            options={items}
            isClearable={isClearable}
            onChange={onChange}
            value={value}
            className={isMultiSearch ? "basic-multi-select multi-search-input " : "basic-single single-search-input " + className}
            classNamePrefix="select"
            placeholder={placeholder}
            components={{ Option: Option ? Option : CustomOption }}
            menuPortalTarget={document.body}
            filterOption={filterOption || defaultFilterOption}
            onKeyDown={handleKeyDown}
            isSearchable={true}
        />
    );
};

export default CustomSearchInput;