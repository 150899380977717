import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PermissionsGate from "../PermissionsGate";
import InventaireTout from "./tout/InventaireTout";
import InventaireSupport from "./support/InventaireSupport";
import CustomerView from "./clients/CustomerView";
import { EngineProvider } from "../../../contexts/EngineContext";

const Inventaire = () => {

    const moduleName = "inventaire";

    return (
        <EngineProvider>
            <Routes>
                <Route
                    path="/clients"
                    element={
                        <PermissionsGate module={moduleName} submodule="clients">
                            <InventaireTout />
                        </PermissionsGate>
                    }
                />
                <Route
                    path="/clients/:customerId"
                    element={
                        <PermissionsGate module={moduleName} submodule="clients">
                            <CustomerView />
                        </PermissionsGate>
                    }
                />
                <Route
                    path="/support"
                    element={
                        <PermissionsGate module={moduleName} submodule="support">
                            <InventaireSupport />
                        </PermissionsGate>
                    }
                />
                <Route path="*" element={<Navigate to="/clients" />} />
            </Routes>
        </EngineProvider>
    );
};

export default Inventaire;