import { useParams } from "react-router-dom";
import React, { useState } from "react";
import altelis_logo from "../../assets/images/altelis-full.svg";
import not_found from "../../assets/images/order-404.svg";
import valid from "../../assets/images/valid-subscription.png";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import toastOptions from "../../assets/constants/toast";
import CountdownTimer from "./CountDown";
import { Link, PhoneOutgoing, UploadSimple } from "@phosphor-icons/react";

const MAX_FILE_SIZE = 10 * 1024 * 1024;

const TestSubmit = () => {
    const { testId, userId } = useParams();
    const [refresh, setRefresh] = useState(0);
    const [file, setFile] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [test, setTest] = useState();
    const [user, setUser] = useState();
    const [isFileValid, setIsFileValid] = useState(false);
    const [dragActive, setDragActive] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    React.useEffect(() => {
        setIsLoaded(false);
        axios
            .get(process.env.REACT_APP_UPA_API_HOST + "tests/" + testId + "/" + userId)
            .then((response) => {
                if (response.data) {
                    setTest(response.data.test);
                    setUser(response.data);
                } else {
                    setError(true);
                }
                setIsLoaded(true);
            })
            .catch(() => {
                setError(true);
                setIsLoaded(true);
            });
    }, [refresh, testId, userId]);

    const handleRemaining = (remaining) => {
        if (remaining < 0) {
            setRefresh(refresh + 1);
        }
    };

    const handleFileChange = (selectedFile) => {
        if (!selectedFile) {
            setIsFileValid(false);
            setFile(null);
            return;
        }
        const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
        if (fileExtension !== "zip") {
            toast.error("Seuls les fichiers .zip sont acceptés", toastOptions);
            setIsFileValid(false);
            setFile(null);
            return;
        }
        if (selectedFile.size > MAX_FILE_SIZE) {
            toast.error("Le fichier est trop volumineux. Limite : 10 Mo", toastOptions);
            setIsFileValid(false);
            setFile(null);
            return;
        }
        setFile(selectedFile);
        setIsFileValid(true);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragActive(true);
    };

    const handleDragLeave = () => {
        setDragActive(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragActive(false);
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
            handleFileChange(droppedFiles[0]);
        }
    };

    const handleInputChange = (e) => {
        const selectedFile = e.target.files[0];
        handleFileChange(selectedFile);
    };

    const submitFile = () => {
        if (file && isFileValid) {
            const formData = new FormData();
            formData.append("file", file);
            fetch(`${process.env.REACT_APP_UPA_API_HOST}tests/${testId}/${userId}`, {
                method: "POST",
                body: formData,
            })
                .then((response) => {
                    const contentType = response.headers.get("content-type") || "";
                    if (!response.ok) {
                        if (contentType.includes("application/json")) {
                            return response.text().then((text) => {
                                let json = {};
                                try {
                                    json = text ? JSON.parse(text) : {};
                                } catch {
                                    throw new Error("Erreur lors du traitement de la réponse");
                                }
                                throw new Error(json.message || "Une erreur est survenue. Veuillez vérifier l'API.");
                            });
                        } else {
                            throw new Error("Une erreur est survenue. Veuillez vérifier l'API.");
                        }
                    }
                    if (contentType.includes("application/json")) {
                        return response.text().then((text) => (text ? JSON.parse(text) : { message: "Fichier téléchargé avec succès." }));
                    }
                    return { message: "Fichier téléchargé avec succès." };
                })
                .then((data) => {
                    toast.success(data.message || "Votre fichier a bien été déposé", toastOptions);
                    setFile(null);
                    setIsFileValid(false);
                    setRefresh(refresh + 1);
                })
                .catch((error) => {
                    toast.error(error.message || "Erreur lors du dépôt du fichier", toastOptions);
                });
        }
    };

    return isLoaded ? (
        !error ? (
            <div className="order-portal">
                <div className="portal-header">
                    <div className="portal-title">
                        <div className="logo-container">
                            <img src={altelis_logo} alt="portal-logo" width={100} />
                        </div>
                        <div>
                            <h1>{test.name}</h1>
                            <span>#{test.id}</span>
                        </div>
                    </div>
                    <a href="tel:01 85 09 74 06" className="main-button blue-button">
                        <PhoneOutgoing size={16} weight="bold" />
                        <span>01 85 09 74 06</span>
                    </a>
                </div>
                {dayjs() > dayjs(test.startDate) && dayjs() < dayjs(test.stopDate) ? (
                    <div className="test-content">
                        <h1>
                            Bonjour, {user.firstName} <span style={{ textTransform: "uppercase", color: "var(--blue-100)" }}>{user.lastName.toUpperCase()}</span>
                        </h1>
                        {dayjs() < dayjs(user.startDate) ? (
                            <div>
                                <div className="test-duration">
                                    <span>
                                        Votre test est prévu le <strong>{dayjs(user.startDate).format("dddd DD MMMM à HH:mm")}</strong> pour une durée de{" "}
                                        {test.hours > 0 ? (
                                            <strong>
                                                {test.hours} heure{test.hours > 1 ? "s" : ""}{" "}
                                            </strong>
                                        ) : null}
                                        {test.minutes > 0 ? (
                                            <strong>
                                                {test.minutes} minute{test.minutes > 1 ? "s" : ""}
                                            </strong>
                                        ) : null}
                                        .
                                    </span>
                                </div>
                                <div className="test-duration">
                                    <span>Le sujet sera disponible sur cette page dès le début de la période du test.</span>
                                    <CountdownTimer targetDate={dayjs(user.startDate).valueOf()} introText="Votre test commence dans : " setRemaining={handleRemaining} />
                                </div>
                            </div>
                        ) : dayjs() < dayjs(user.stopDate) ? (
                            <div className="test-duration">
                                <span>Bonne chance !</span>
                                <CountdownTimer targetDate={dayjs(user.stopDate).valueOf()} displayDays={false} introText="Votre test se termine dans : " setRemaining={handleRemaining} />
                                <a href={test.subjectLink} target="_blank" rel="noreferrer">
                                    <button className="main-button blue-button">
                                        <Link size={16} weight="bold" />
                                        Lien du sujet
                                    </button>
                                </a>
                                <div className="test-reminder">
                                    <span>
                                        <strong>Rappel : </strong> Seuls les fichiers compressés au format <strong>.zip</strong> sont acceptés.
                                    </span>
                                    <p className="info-message">
                                        Si vous n'arrivez pas à déposer votre test, veuillez l'envoyer à <a href="mailto:candidature@altelis.com">candidature@altelis.com</a>
                                    </p>
                                </div>
                                <div className={dragActive ? "file-container active" : "file-container"} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
                                    <div className="file-drop">
                                        <label htmlFor="file" className={file ? "metas-file-chooser data" : "metas-file-chooser"}>
                                            <span>{!file ? "Glissez ou téléversez votre fichier ici" : file.name}</span>
                                            <input accept=".zip" type="file" name="file" id="file" onChange={handleInputChange} style={{ display: "none" }} />
                                        </label>
                                        <span className="span-reminder">
                                            <strong>(Taille maximale. : 10 Mo)</strong>
                                        </span>
                                    </div>
                                    <button
                                        className="main-button blue-button"
                                        onClick={submitFile}
                                        disabled={!isFileValid || isSubmitting}
                                        style={{ cursor: !isFileValid || isSubmitting ? "not-allowed" : "pointer" }}
                                    >
                                        <UploadSimple size={16} weight="bold" />
                                        Déposer
                                    </button>
                                </div>
                                {user.submittedAt ? (
                                    <div className="test-duration">
                                        <span>
                                            Votre fichier a été déposé à <strong>{dayjs(user.submittedAt).subtract(1, "hour").format("HH:mm:ss")}</strong>
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                        ) : (
                            <div>
                                <div>
                                    <img src={valid} className="test-done" alt="not found" width={120} />
                                </div>
                                <div className="test-duration">
                                    <span>
                                        Votre test est <strong>terminé</strong>. Merci pour votre implication !
                                    </span>
                                </div>
                                <div className="test-duration">
                                    <span>Nous reviendrons vers vous au plus vite.</span>
                                </div>
                                <div className="test-duration">
                                    <span>
                                        <strong>L'équipe Altelis</strong>
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="portal-content flex-view">
                        <div className="order-404">
                            <img src={not_found} alt="not found" width={400} />
                            <h1>Oups...</h1>
                            <p>
                                Ce test n'est disponible que du <strong>{dayjs(test.startDate).format("DD MMMM YYYY")}</strong> au <strong>{dayjs(test.stopDate).format("DD MMMM YYYY")}</strong>
                            </p>
                        </div>
                    </div>
                )}
            </div>
        ) : (
            <div className="portal-content flex-view">
                <div className="order-404">
                    <img src={not_found} alt="not found" width={400} />
                    <h1>Oups...</h1>
                    <p>Le test que vous cherchez n'est pas disponible pour le moment. Merci de réessayer plus tard.</p>
                </div>
            </div>
        )
    ) : (
        <div className="loader-container absolute">
            <CircularProgress size={30} />
        </div>
    );
};

export default TestSubmit;
