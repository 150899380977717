import React, { useContext, useState } from "react";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import mainLogo from "../../assets/images/main-logo.png";
import AuthContext from "../../contexts/AuthContext";
import Accounting from "./accounting/Accounting";
import "../../assets/styles/AdminPanel.css";
import Tippy from "@tippyjs/react";
import CustomSearchInput from "../custom/CustomSearchInput";
import axios from "axios";
import UsersInfos from "./users/Users";
import { CircularProgress } from "@mui/material";
import Hiring from "./hiring/Hiring";
import CustomNavbarOption from "../custom/CustomNavbarOption";
import Inventaire from "./inventaire/Inventaire";
import Roles from "./roles/Roles";
import { ArrowLeft, CaretDown, SignOut, User, UserCircleDashed, Users } from "@phosphor-icons/react";
import Tracking from "./tracking/Tracking";

const AdminPanel = ({ countData }) => {

    const adminLocation = window.location.href.replace(process.env.REACT_APP_UPA_INTERFACE_HOST + "admin/", "");
    const navigate = useNavigate();
    const { user, token, setToken, setUser } = useContext(AuthContext);
    const [searchData, setSearchData] = useState([]);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + "pennylane/customers", {
            headers: { Authorization: "Bearer " + token },
        }).then((response) => {
            setSearchData(response.data);
        }).catch((error) => console.log("Error fetching search data:", error));
    }, [token]);

    const handleDisconnect = () => {
        localStorage.clear();
        setToken(null);
        setUser(null);
    };

    const handleSearchbarSelect = (newValue) => {
        navigate("/admin/inventaire/clients/" + newValue.id);
    };

    const popover = (
        <div className="user-options">
            {user.role.modules.some((mod) => mod.value === "users" && mod.submodules.includes("profile")) ? (
                <Link to={`/admin/users/${user.id}`} className="user-options-link" onClick={popoverVisible ? hide : show}>
                    <div className="user-options-item users">
                        <User size={16} weight="bold" />
                        <span>Profil</span>
                    </div>
                </Link>
            ) : null}
            {user.role.modules.some((mod) => mod.value === "users" && mod.submodules.includes("users-list")) ? (
                <Link to="users" className="user-options-link" onClick={popoverVisible ? hide : show}>
                    <div className="user-options-item users">
                        <Users size={16} weight="bold" />
                        <span>Utilisateurs</span>
                    </div>
                </Link>
            ) : null}
            {user.role.modules.some((mod) => mod.value === "roles") ? (
                <Link to="roles" className="user-options-link" onClick={popoverVisible ? hide : show}>
                    <div className="user-options-item roles">
                        <UserCircleDashed size={16} weight="bold" />
                        <span>Rôles</span>
                    </div>
                </Link>
            ) : null}
            <div className="user-info-hr-horizontal"></div>
            <div className="user-options-item logout" onClick={handleDisconnect}>
                <SignOut size={16} weight="bold" />
                <span>Se déconnecter</span>
            </div>
        </div>
    );

    return user ? (
        <main className="main-app">
            <nav className="main-menu">
                <div className="main-menu-container">
                    <div className="app-logo">
                        <img src={mainLogo} alt="main-logo" width={130} />
                    </div>
                    <div className="menu-container">
                        {user.role.modules.some((mod) => mod.value === "accounting") ? (
                            <div>
                                <div className="menu-item accounting has-sub-items">
                                    <span>Comptabilité</span>
                                </div>
                                {user.role.modules.some((mod) => mod.value === "accounting" && mod.submodules.includes("metas-billing")) ? (
                                    <Link to="accounting/metas-billing" className="menu-link">
                                        <div className={"menu-sub-item" + (adminLocation.includes("accounting/metas-billing") ? " active" : "")}>
                                            <span>Fact. métas</span>
                                            <div className="sub-item-info-count">{countData.metasHotels}</div>
                                        </div>
                                    </Link>
                                ) : null}
                                {user.role.modules.some((mod) => mod.value === "accounting" && mod.submodules.includes("pennylane-subscription")) ? (
                                    <Link to="accounting/pennylane-subscription" className="menu-link">
                                        <div className={"menu-sub-item" + (adminLocation.includes("accounting/pennylane-subscription") ? " active" : "")}>
                                            <span>Abonnements</span>
                                            <div className="sub-item-info-count">{countData.subscriptions}</div>
                                        </div>
                                    </Link>
                                ) : null}
                            </div>
                        ) : null}
                        {user.role.modules.some((mod) => mod.value === "inventaire") ? (
                            <div>
                                <div className="menu-item inventaire has-sub-items">
                                    <span>Inventaire</span>
                                </div>
                                {user.role.modules.some((mod) => mod.value === "inventaire" && mod.submodules.includes("clients")) ? (
                                    <Link to="/admin/inventaire/clients" className="menu-link">
                                        <div className={"menu-sub-item" + (adminLocation.includes("inventaire/clients") ? " active" : "")}>
                                            <span>Clients</span>
                                            <div className="sub-item-info-count">{countData.customers}</div>
                                        </div>
                                    </Link>
                                ) : null}
                                {user.role.modules.some((mod) => mod.value === "inventaire" && mod.submodules.includes("support")) ? (
                                    <Link to="inventaire/support" className="menu-link">
                                        <div className={"menu-sub-item" + (adminLocation.includes("inventaire/support") ? " active" : "")}>
                                            <span>Support</span>
                                        </div>
                                    </Link>
                                ) : null}
                            </div>
                        ) : null}
                        {user.role.modules.some((mod) => mod.value === "projects") ? (
                            <div>
                                <div className="menu-item projects has-sub-items">
                                    <span>Projets</span>
                                </div>
                                {user.role.modules.some((mod) => mod.value === "projects" && mod.submodules.includes("tracking")) ? (
                                    <Link to="projects/tracking" className="menu-link">
                                        <div className={"menu-sub-item" + (adminLocation.includes("projects/tracking") ? " active" : "")}>
                                            <span>Saisie d'activité</span>
                                        </div>
                                    </Link>
                                ) : null}
                            </div>
                        ) : null}
                        {user.role.modules.some((mod) => mod.value === "hiring") ? (
                            <div>
                                <div className="menu-item tests has-sub-items">
                                    <span>Recrutement</span>
                                </div>
                                {user.role.modules.some((mod) => mod.value === "hiring" && mod.submodules.includes("tests")) ? (
                                    <Link to="hiring/tests" className="menu-link">
                                        <div className={"menu-sub-item" + (adminLocation.includes("hiring/tests") ? " active" : "")}>
                                            <span>Tests</span>
                                            <div className="sub-item-info-count">{countData.tests}</div>
                                        </div>
                                    </Link>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </div>
            </nav>
            <div className="main-app-right-part">
                <div className="main-navbar-container">
                    <div className="main-navbar">
                        <button className="main-button return-button" onClick={() => navigate(-1)}>
                            <ArrowLeft size={16} weight="bold" />
                        </button>
                        <div className="searchbar-container">
                            <div className="searchbar-item">
                                <div className="input-container">
                                    <CustomSearchInput
                                        Option={CustomNavbarOption}
                                        placeholder={null}
                                        labelType="customer"
                                        entity={searchData}
                                        onChange={handleSearchbarSelect}
                                        value={null}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="user-info-hr-vertical"></div>
                        <div className="user-info-container">
                            <Tippy
                                content={popover}
                                allowHTML={true}
                                placement={"bottom-end"}
                                offset={[0, 10]}
                                visible={popoverVisible}
                                onClickOutside={hide}
                                interactive={true}
                                appendTo={"parent"}
                            >
                                <div className="user-item" onClick={popoverVisible ? hide : show}>
                                    {user && user.backgroundColor && (
                                        <div
                                            className="member-icon"
                                            style={{
                                                backgroundColor: user.backgroundColor,
                                            }}
                                        >
                                            {user.firstName.charAt(0)}
                                            {user.lastName.charAt(0)}
                                        </div>
                                    )}
                                    <span>{user ? user.firstName + " " + user.lastName : <CircularProgress size={20} />}</span>
                                    <CaretDown size={16} weight="bold" />
                                </div>
                            </Tippy>
                        </div>
                    </div>
                </div>
                <div className="main-router-content">
                    <Routes>
                        <Route path="accounting/*" element={<Accounting />} />
                        <Route path="hiring/*" element={<Hiring />} />
                        <Route path="users/*" element={<UsersInfos />} />
                        <Route path="roles/*" element={<Roles />} />
                        <Route path="projects/*" element={<Tracking />} />
                        <Route path="inventaire/*" element={<Inventaire />} />
                        <Route path="*" element={<Navigate to={user.role.modules[0].value} />} />
                    </Routes>
                </div>
            </div>
        </main>
    ) : (
        <div className="loader-container absolute">
            <CircularProgress size={30} />
        </div>
    );
};

export default AdminPanel;
