import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import CustomTag from "../../../custom/CustomTag";
import { CircularProgress, IconButton, Tab, Tabs } from "@mui/material";
import toastOptions from "../../../../assets/constants/toast";
import CustomList from "../../../custom/CustomList";
import MetasConfigView from "../../accounting/contacts/metas/ConfigView";
import MetasConfigAdd from "../../accounting/contacts/metas/ConfigAdd";
import MetasConfigEdit from "../../accounting/contacts/metas/ConfigEdit";
import EngineConfigView from "../../accounting/contacts/engines/ConfigView";
import EngineConfigAdd from "../../accounting/contacts/engines/ConfigAdd";
import EngineConfigEdit from "../../accounting/contacts/engines/ConfigEdit";
import ContactSupport from "./ContactSupport";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { toast } from "react-toastify";
import FormData from "form-data";

const CustomerView = () => {

    const { customerId } = useParams();
    const [customer, setCustomer] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [edit, setEdit] = useState({ website: false });
    const [tab, setTab] = useState(0);
    const [metasView, setMetasView] = useState("view");
    const [enginesView, setEnginesView] = useState({ name: "view", params: null });
    const [refresh, setRefresh] = useState(0);
    const { token } = useContext(AuthContext);

    useEffect(() => {
        setIsLoaded(false);
        axios.get(`${process.env.REACT_APP_UPA_API_HOST}pennylane/customers/${customerId}`, { headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
                if (response.data) {
                    setCustomer(response.data);
                    setIsLoaded(true);
                }
            })
            .catch((error) => console.log(error));
    }, [refresh, customerId, token]);

    const handleUpdate = (logoUrl = null) => {
        axios.put(
            process.env.REACT_APP_UPA_API_HOST + "pennylane/customers/" + customerId,
            {
                website: customer.website,
                logo: logoUrl ? logoUrl : customer.logo,
            },
            { headers: { Authorization: "Bearer " + token } }
        ).then((response) => {
            if (response.data) {
                setRefresh(refresh + 1);
                setEdit({ ...edit, website: false });
                toast.success("Client mis à jour avec succès", toastOptions);
            }
        }).catch((error) => console.log(error));
    };

    const recurringCols = React.useMemo(
        () => [
            {
                Header: "Article / Produit",
                accessor: "label",
                icon: "article",
                Cell: ({ cell: { value } }) => <CustomTag value={value} />,
            },
            {
                Header: "Récurrence",
                accessor: "subscription",
                icon: "calendar",
                Cell: ({ value }) => {
                    const { interval, ruleType } = value;
                    return `Tous les ${interval} ${ruleType === "monthly" ? "mois" : "ans"}`;
                },
            },
            {
                Header: "Montant facturé",
                accessor: "priceBeforeTax",
                icon: "euro",
                Cell: ({ cell: { value } }) => <CustomTag className="custom-tag" value={value.toFixed(2) + ' €'} />,
            },
        ],
        []
    );

    const renderMetas = () => {
        return <p className='waiting-order-warning'>En cours de migration Zoho vers Pennylane.</p>;
        switch (metasView) {
            case "view":
                return <MetasConfigView setMetasView={setMetasView} customer={customer} refresh={refresh} setRefresh={setRefresh} />;
            case "edit":
                return <MetasConfigEdit setMetasView={setMetasView} customer={customer} refresh={refresh} setRefresh={setRefresh} />;
            case "add":
                return <MetasConfigAdd setMetasView={setMetasView} customer={customer} zohoId={customer.id} refresh={refresh} setRefresh={setRefresh} />;
            default:
                return (
                    <div className="tab-container">
                        <CustomTag className="margin-bottom" value="Ce customer ne possède pas de configuration métas" />
                        <button className="main-button blue-button plus" onClick={() => setMetasView("add")}>
                            Ajouter une configuration métas
                        </button>
                    </div>
                );
        }
    };

    // TODO : Migrate Altelis Engines from Zoho to Pennylane
    const renderEngine = () => {
        return <p className='waiting-order-warning'>En cours de migration Zoho vers Pennylane.</p>;
        switch (enginesView.name) {
            case "edit":
                return <EngineConfigEdit setEnginesView={setEnginesView} configToEdit={enginesView.params} refresh={refresh} setRefresh={setRefresh} />;
            case "add":
                return <EngineConfigAdd setEnginesView={setEnginesView} customer={customer} zohoId={customer.id} refresh={refresh} setRefresh={setRefresh} />;
            default:
                return customer.engineConfigs?.length > 0 ? (
                    customer.engineConfigs.map((config, index) => <EngineConfigView key={index} setEnginesView={setEnginesView} config={config} refresh={refresh} setRefresh={setRefresh} />)
                ) : (
                    <div className="tab-container">
                        <CustomTag className="margin-bottom" value="Ce customer ne possède pas de configuration moteur" />
                        <button className="main-button blue-button plus" onClick={() => setEnginesView({ name: "add" })}>
                            Ajouter une configuration moteur
                        </button>
                    </div>
                );
        }
    };

    const uploadNewLogo = (event) => {
        if (event.target.files[0]) {
            let data = new FormData();
            const extension = event.target.files[0].name.split(".").pop();
            data.append("path", "/altelis-tools/customers/" + customer.id + "." + extension);
            data.append("image", event.target.files[0]);
            axios
                .post("https://image.store.altelis.com/api/v1/upload", data)
                .then((response) => {
                    handleUpdate("https://image.store.altelis.com" + response.data.path);
                })
                .catch((err) => console.log(err));
        }
    };

    return isLoaded ? (
        <div className="view-container">
            <div className="customer-view-header">
                <div className="customer-view-data">
                    <label htmlFor="file" className="customer-view-logo">
                        {customer.logo ? <img src={customer.logo} alt="customer-logo" /> : <img src="https://image.store.altelis.com/images/altelis-tools/logo-altelis.png" alt="customer-logo" />}
                        <input accept=".png, .jpg, .jpeg, .svg" type="file" name="file" id="file" onChange={uploadNewLogo} />
                    </label>
                    <div className="customer-view-wrapper">
                        <div className="customer-view-title">
                            <h1>{customer.name}</h1>
                        </div>
                        <div className="customer-view-details">
                            <div className="input-row-container">
                                <span className="input-label small-input statut">Référence</span>
                                <CustomTag value={customer.reference || "Non renseigné"} className={!customer.reference ? 'deactivated' : ''} />
                            </div>
                            <div className="input-row-container">
                                <span className="input-label small-input support">Support</span>
                                <CustomTag value={customer.supportInfo.isPremium ? "Premium" : (customer.supportInfo.isEssential ? "Essentiel" : "Aucun")} className={`margin-right uppercase ${customer.supportInfo.isPremium ? "premium" : (customer.supportInfo.isEssential ? "essential" : "no-assistance")}`} />
                                <div className="conso-wrapper">
                                    <CustomTag value={customer.supportHours + (customer.supportInfo.isPremium ? ` / ${ customer.supportInfo.premiumHours} h` : ' h')} className={ customer.supportInfo.isPremium ? (customer.supportHours > customer.supportInfo.premiumHours ? 'no-assistance' : 'success') : customer.supportHours > 0 ? 'no-assistance' : 'success'} />
                                </div>
                            </div>
                            <div className="input-row-container">
                                <span className="input-label small-input link">Lien du site</span>
                                { edit.website ? (
                                    <div className="input-container without-icon">
                                        <input
                                            value={customer.website ? customer.website : ""}
                                            className="classic-input margin-right"
                                            placeholder="Entrez une URL"
                                            onChange={(e) =>
                                                setCustomer({
                                                    ...customer,
                                                    website: e.target.value,
                                                })
                                            }
                                        />
                                        <IconButton
                                            aria-label="delete"
                                            size="small"
                                            onClick={() => {
                                                setRefresh(refresh + 1);
                                                setEdit({
                                                    ...edit,
                                                    website: false,
                                                });
                                            }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton aria-label="delete" size="small" onClick={() => handleUpdate()}>
                                            <DoneIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                ) : (
                                    <>
                                        <a href={"https://" + customer.website} target="_blank" rel="noreferrer">
                                            <CustomTag value={customer.website ? customer.website : "Non renseigné"} className={"margin-right " + (customer.website ? "" : "deactivated")} />
                                        </a>
                                        <IconButton
                                            aria-label="delete"
                                            size="small"
                                            onClick={() =>
                                                setEdit({
                                                    ...edit,
                                                    website: true,
                                                })
                                            }
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tabs-container">
                <Tabs value={tab} onChange={(event, value) => setTab(value)}>
                    <Tab value={0} label={<span className={`customer-dot ${customer.subscriptions.length > 0 && "active"}`}>Récurrent</span>} />
                    <Tab value={1} label={<span className={`customer-dot ${customer.supportInfo.isPremium && "active"}`}>Support</span>} />
                    <Tab value={2} label={<span className={customer.metasHotels?.length > 0 ? "customer-dot active" : "customer-dot inactive"}>Métas</span>} />
                    <Tab value={3} label={<span className={customer.metasHotels?.length > 0 ? "customer-dot active" : "customer-dot inactive"}>Moteur</span>} />
                </Tabs>
                <div role="tabpanel" hidden={tab !== 0}>
                    { customer.subscriptions.length > 0 ? (
                        <>
                            <CustomList
                                columns={recurringCols}
                                isSelectable={false}
                                isClickable={false}
                                isFetchable={false}
                                isPaginable={false}
                                entity={customer.subscriptions.flatMap(s => s.lines.map(l => ({...l, subscription: s}))).filter(l => l.subscription.status === "in_progress")}
                            />
                            <div className='mensual-return-rate'>
                                <span>Récurrent mensuel :</span>
                                <span className='price'>{customer.mensualRateReturn.toFixed(2)} €</span>
                            </div>
                        </>
                    ) : (
                        <p className='waiting-order-warning'>Ce client ne possède aucune facturation récurrente pour le moment.</p>
                    )}
                </div>
                <div role="tabpanel" hidden={tab !== 1}>
                    <ContactSupport customer={customer} refresh={refresh} setRefresh={setRefresh} />
                </div>
                <div role="tabpanel" hidden={tab !== 2}>
                    {renderMetas()}
                </div>
                <div role="tabpanel" hidden={tab !== 3}>
                    <p className='waiting-order-warning'>En cours de migration Zoho vers Pennylane.</p>
                </div>
            </div>
        </div>
    ) : (
        <div className="loader-container absolute">
            <CircularProgress size={30} />
        </div>
    );
};

export default CustomerView;
