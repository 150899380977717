import React, {useContext} from "react";
import CustomTag from "../../../custom/CustomTag";
import CustomList from "../../../custom/CustomList";
import dayjs from "dayjs";
import {CircularProgress} from "@mui/material";
import {ArrowsClockwise} from "@phosphor-icons/react";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import {toast} from "react-toastify";
import toastOptions from "../../../../assets/constants/toast";

const InventaireTout = () => {

    const { token } = useContext(AuthContext);
    const [isSyncing, setIsSyncing] = React.useState(false);
    const [lastUpdatedAt, setLastUpdatedAt] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [customers, setCustomers] = React.useState(null);
    const [refresh, setRefresh] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        axios.get(process.env.REACT_APP_UPA_API_HOST + "pennylane/customers", {
            headers: { Authorization: "Bearer " + token },
        }).then((response) => {
            setLastUpdatedAt(response.data.length > 0 ? response.data[0].updatedAt : null);
            setCustomers(response.data);
        }).catch((error) => {
            console.log("Error fetching search data:", error)
        }).finally(() => setLoading(false));
    }, [token, refresh]);

    const syncData = async () => {
        setIsSyncing(true);
        axios.get(process.env.REACT_APP_UPA_API_HOST + "pennylane/sync-customers", {
            headers: { Authorization: "Bearer " + token },
        }).then(() => {
            setRefresh(!refresh);
            toast.success("Les clients ont bien été synchronisés", toastOptions);
        }).catch((error) => {
            toast.error("Une erreur est survenue", toastOptions);
            console.log(error);
        }).finally(() => setIsSyncing(false));
    }

    const customerCols = React.useMemo(
        () => [
            {
                Header: "ID",
                accessor: "reference",
                icon: "role",
                Cell: ({ cell: { value } }) => <CustomTag value={value || '000'} />,
            },
            {
                Header: "Nom",
                accessor: "name",
                icon: "user",
                Cell: (row) => (
                    <div className="contact-list-customer-name">
                        <div className="contact-list-img">
                            <img src={ row.row.original.logo ? row.row.original.logo : "https://image.store.altelis.com/images/altelis-tools/logo-altelis.png" } alt="contact-logo" />
                        </div>
                        <span>{row.row.original.name}</span>
                    </div>
                ),
            },
            {
                Header: "Support",
                accessor: "supportInfo",
                icon: "support",
                Cell: ({ cell: { value } }) => {
                    return <CustomTag value={value.isPremium ? "Premium" : (value.isEssential ? "Essentiel" : "Aucun")} className={`uppercase ${value.isPremium ? "premium" : (value.isEssential ? "essential" : "no-assistance")}`} />;
                },
            },
            {
                Header: "Conso.",
                icon: "conso",
                accessor: "supportHours",
                className: "col-conso",
                Cell: ({ row }) => {
                    const supportInfo = row.original.supportInfo;
                    return (
                        <div className="conso-wrapper">
                            <CustomTag value={row.original.supportHours + ' h'} className={ supportInfo.isPremium ? (row.original.supportHours > supportInfo.premiumHours ? 'no-assistance' : 'success') : row.original.supportHours > 0 ? 'no-assistance' : 'success'} />
                            { supportInfo.isPremium ? ` / ${ supportInfo.premiumHours} h` : null }
                        </div>
                    )
                },
            },
            {
                Header: "MRR",
                icon: "euro",
                accessor: "mensualRateReturn",
                className: "col-conso",
                Cell: ({ cell: { value } }) => <CustomTag value={value.toFixed(2) + ' €'} />,
            }
        ],
        []
    );

    return (
        <div className="view-container">
            <div className="view-header">
                <div className='header-title pennylane'>
                    <h1>Clients</h1>
                </div>
                <div className="view-buttons">
                    { lastUpdatedAt && (
                        <div className="header-info">
                            <span>Dernière MÀJ : {dayjs(lastUpdatedAt).format("DD/MM à HH:mm")}</span>
                        </div>
                    )}
                    <button className="main-button" onClick={syncData} disabled={isSyncing}>
                        { isSyncing ? (
                            <>
                                <CircularProgress size={14} sx={{ color: "white" }} />
                                <span>Synchronisation en cours...</span>
                            </>
                        ) : (
                            <>
                                <ArrowsClockwise size={16} weight="bold" />
                                Synchroniser
                            </>
                        )}
                    </button>
                </div>
            </div>
            { !loading ? (
                <div className="tabs-container">
                    <CustomList
                        entity={customers}
                        columns={customerCols}
                        isSelectable={false}
                        isScrollable={true}
                        limit={100}
                        entityType="clients"
                    />
                </div>
            ) : (
                <div className="loader-container">
                    <CircularProgress size={30} />
                </div>
            )}

        </div>
    );
};

export default InventaireTout;
