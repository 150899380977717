import React, { useContext, useEffect, useRef, useState } from "react";
import { Tabs, Tab, CircularProgress } from "@mui/material";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Calendar, ListDashes, Plus, CirclesThree, Folder, CaretDown, Eraser, CheckCircle, CaretLeft, CaretRight, WarningCircle } from "@phosphor-icons/react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import TimeTable from "./TimeTable";
import ModelsButton from "./ModelsButton";
import AutomationsButton from "./AutomationsButton";
import CustomTag from "../../custom/CustomTag";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import WeekTabLabel from "./WeekTabLabel";
dayjs.extend(isoWeek);


const TimeEntryList = () => {
    const [tab, setTab] = useState(0);
    const [entries, setEntries] = useState([]);
    const [projects, setProjects] = useState([]);
    const [sections, setSections] = useState([]);
    const [models, setModels] = useState([]);
    const [automations, setAutomations] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterProject, setFilterProject] = useState(null);
    const [filterDate, setFilterDate] = useState(null);
    const [isProjectPopupOpen, setProjectPopupOpen] = useState(false);
    const [projectSearch, setProjectSearch] = useState("");
    const [isDatePopupOpen, setDatePopupOpen] = useState(false);
    const [activeDay, setActiveDay] = useState(dayjs());
    const { token, user } = useContext(AuthContext);
    const urlParams = new URLSearchParams(window.location.search);
    const asanaAuth = urlParams.get("asana_auth");
    const projectPopupRef = useRef(null);
    const datePopupRef = useRef(null);

    useEffect(() => console.log(entries), [entries]);

    useEffect(() => {
        if (asanaAuth && asanaAuth === "success") {
            toast.success("Connexion avec Asana réussie", toastOptions);
            urlParams.delete("asana_auth");
            window.history.replaceState({}, document.title, window.location.pathname);
        } else if (asanaAuth && asanaAuth === "failure") {
            toast.error("Connexion avec Asana échouée", toastOptions);
            urlParams.delete("asana_auth");
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }, [asanaAuth]);

    useEffect(() => {
        setLoading(true);
        axios
            .get(process.env.REACT_APP_UPA_API_HOST + "tracking/data", {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setEntries(
                    response.data.entries.map((entry) => ({
                        ...entry,
                        projectId: entry.task.section.projectId,
                    }))
                );
                setProjects(response.data.projects);
                setSections(response.data.sections);
                setTasks(response.data.tasks);
                setModels(response.data.models);
                setAutomations(response.data.automations);
                setLoading(false);
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                setLoading(false);
            });
    }, [token]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isProjectPopupOpen && projectPopupRef.current && !projectPopupRef.current.contains(event.target)) {
                setProjectPopupOpen(false);
            }
            if (isDatePopupOpen && datePopupRef.current && !datePopupRef.current.contains(event.target)) {
                setDatePopupOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [isProjectPopupOpen, isDatePopupOpen]);

    // const toggleProjectPopup = () => {
    //     setProjectPopupOpen((prev) => !prev);
    //     if (isDatePopupOpen) setDatePopupOpen(false);
    // };

    // const toggleDatePopup = () => {
    //     setDatePopupOpen((prev) => !prev);
    //     if (isProjectPopupOpen) setProjectPopupOpen(false);
    // };

    const handleAddEntry = () => {
        setEntries([...entries, { tempId: Date.now(), date: activeDay.format("YYYY-MM-DD"), comments: "", duration: 0 }]);
    };

    const getDayHours = (day) => {
        return entries
            .filter((entry) => entry.date === activeDay.isoWeekday(day).format("YYYY-MM-DD"))
            .reduce((sum, entry) => {
                const dur = typeof entry.duration === "number" ? entry.duration : parseFloat(entry.duration) || 0;
                return sum + dur;
            }, 0)
            .toFixed(2);
    };

    return !loading ? (
        <div className="view-container tracking">
            <div className="view-header">
                <div className="header-title projects">
                    <h1>Saisie d'activité</h1>
                </div>
                <div className="view-buttons">
                    {!user.asanaAccessToken ? (
                        <a
                            href={`https://app.asana.com/-/oauth_authorize?client_id=${process.env.REACT_APP_ASANA_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_UPA_API_HOST}asana/auth&response_type=code&state=${user.id}`}
                        >
                            <button className="main-button asana-button">
                                <CirclesThree size={20} weight="fill" color="#ff584a" />
                                Se connecter à Asana
                            </button>
                        </a>
                    ) : (
                        <>
                            <AutomationsButton models={models} automations={automations} setAutomations={setAutomations} />
                            <div className="custom-dropdown-button">
                                <button className="main-button blue-button dropdown-left" onClick={handleAddEntry}>
                                    <Plus size={16} weight="bold" />
                                    Nouvelle saisie
                                </button>
                                <ModelsButton
                                    models={models}
                                    setModels={setModels}
                                    projects={projects}
                                    sections={sections}
                                    tasks={tasks}
                                    entries={entries}
                                    setEntries={setEntries}
                                    automations={automations}
                                    setAutomations={setAutomations}
                                    activeDay={activeDay}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            {!user.asanaAccessToken && (
                <>
                    <CustomTag value="La connexion à Asana est nécessaire pour commencer la saisie des temps" />
                    <div className="asana-blur-auth" />
                </>
            )}
            <div className="tabs-container">
                <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
                    <Tab value={0} label="Semaine" icon={<Calendar size={16} weight="bold" />} iconPosition="start" />
                    <Tab value={1} label="Tout" icon={<ListDashes size={16} weight="bold" />} iconPosition="start" />
                </Tabs>
                {tab === 0 && (
                    <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between", gap: 8, height: 65 }}>
                        <div className="week-tab-arrows" onClick={() => setActiveDay(activeDay.subtract(1, "week"))}>
                            <CaretLeft size={16} weight="bold" />
                        </div>
                        <Tabs value={activeDay.isoWeekday()} onChange={(event, newValue) => setActiveDay(activeDay.startOf('week').add(newValue - 1, 'day'))} className="week-tabs" variant="fullWidth" TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}>
                            <Tab value={1} label={<WeekTabLabel dayName="Lundi" dateStr={activeDay.startOf('week').format('D MMM')} hours={getDayHours(1)} />} />
                            <Tab value={2} label={<WeekTabLabel dayName="Mardi" dateStr={activeDay.startOf('week').add(1, 'day').format('D MMM')} hours={getDayHours(2)} />} />
                            <Tab value={3} label={<WeekTabLabel dayName="Mercredi" dateStr={activeDay.startOf('week').add(2, 'day').format('D MMM')} hours={getDayHours(3)} />} />
                            <Tab value={4} label={<WeekTabLabel dayName="Jeudi" dateStr={activeDay.startOf('week').add(3, 'day').format('D MMM')} hours={getDayHours(4)} />} />
                            <Tab value={5} label={<WeekTabLabel dayName="Vendredi" dateStr={activeDay.startOf('week').add(4, 'day').format('D MMM')} hours={getDayHours(5)} />} />
                        </Tabs>
                        <div className="week-tab-arrows" onClick={() => setActiveDay(activeDay.add(1, "week"))}>
                            <CaretRight size={16} weight="bold" />
                        </div>
                    </div>
                )}
                {/* {tab === 1 && (
                    <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                        <div style={{ position: "relative" }}>
                            <div className="custom-filter-button" onClick={() => setProjectPopupOpen(!isProjectPopupOpen)}>
                                <Folder size={12} weight="bold" />
                                {filterProject ? filterProject.name : "Tous"}
                                <CaretDown size={12} weight="bold" />
                            </div>
                            <div
                                ref={projectPopupRef}
                                className="custom-time-entry-popup"
                                style={{
                                    position: "absolute",
                                    top: "100%",
                                    left: 0,
                                    right: 0,
                                    background: "white",
                                    border: "1px solid #eee",
                                    borderRadius: 8,
                                    zIndex: 1000,
                                    marginTop: 4,
                                    maxHeight: 300,
                                    overflowY: "auto",
                                    padding: 8,
                                    transition: "opacity 0.3s ease, transform 0.3s ease",
                                    opacity: isProjectPopupOpen ? 1 : 0,
                                    transform: isProjectPopupOpen ? "translateY(0)" : "translateY(-10px)",
                                    pointerEvents: isProjectPopupOpen ? "auto" : "none",
                                }}
                            >
                                <div className="input-container w-100 mb-2">
                                    <div className="single-search-input">
                                        <input type="text" placeholder="Rechercher un projet" value={projectSearch} onChange={(e) => setProjectSearch(e.target.value)} className="search-input" />
                                    </div>
                                </div>
                                <div>
                                    <div
                                        style={{
                                            padding: 8,
                                            cursor: "pointer",
                                            borderBottom: "1px solid #eee",
                                            fontWeight: filterProject === null ? "700" : "400",
                                            textTransform: "uppercase",
                                        }}
                                        onClick={() => {
                                            setFilterProject(null);
                                            setProjectPopupOpen(false);
                                            setProjectSearch("");
                                        }}
                                    >
                                        Tous les projets
                                    </div>
                                    {projects
                                        .filter((project) => {
                                            const normalize = (str) => {
                                                if (!str) return "";
                                                const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                                                return withoutAccents.replace(/[-_.,;:]/g, " ");
                                            };
                                            return normalize(project.name).includes(normalize(projectSearch));
                                        })
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((project) => (
                                            <div
                                                key={project.id}
                                                style={{
                                                    padding: 8,
                                                    cursor: "pointer",
                                                    borderBottom: "1px solid #eee",
                                                    fontWeight: filterProject === project ? "700" : "400",
                                                }}
                                                onClick={() => {
                                                    setFilterProject(project);
                                                    setProjectPopupOpen(false);
                                                    setProjectSearch("");
                                                }}
                                            >
                                                {project.name}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                        <div style={{ position: "relative" }}>
                            <div className="custom-filter-button" onClick={() => setDatePopupOpen(!isDatePopupOpen)}>
                                <Calendar size={12} weight="bold" />
                                {filterDate ? dayjs(filterDate).format("DD/MM/YYYY") : "Date"}
                                <CaretDown size={12} weight="bold" />
                            </div>
                            <div
                                ref={datePopupRef}
                                className="custom-time-entry-popup"
                                style={{
                                    position: "absolute",
                                    top: "100%",
                                    left: 0,
                                    right: 0,
                                    background: "white",
                                    border: "1px solid #eee",
                                    borderRadius: 8,
                                    zIndex: 1000,
                                    marginTop: 4,
                                    padding: 8,
                                    transition: "opacity 0.3s ease, transform 0.3s ease",
                                    opacity: isDatePopupOpen ? 1 : 0,
                                    transform: isDatePopupOpen ? "translateY(0)" : "translateY(-10px)",
                                    pointerEvents: isDatePopupOpen ? "auto" : "none",
                                }}
                            >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <StaticDatePicker
                                        displayStaticWrapperAs="desktop"
                                        value={filterDate}
                                        onChange={(newDate) => {
                                            setFilterDate(newDate);
                                            setDatePopupOpen(false);
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div style={{ height: "10px", width: "1px", backgroundColor: "#eee" }}></div>
                        <div
                            className="custom-filter-button"
                            onClick={() => {
                                setFilterDate(null);
                                setFilterProject(null);
                                setDatePopupOpen(false);
                            }}
                        >
                            <Eraser size={12} weight="bold" /> Effacer
                        </div>
                    </div>
                )} */}
                {[0, 1].map((index) => (
                    <div key={index} role="tabpanel" hidden={tab !== index}>
                        <TimeTable
                            entries={entries}
                            setEntries={setEntries}
                            projects={projects}
                            sections={sections}
                            tasks={tasks}
                            handleAddEntry={handleAddEntry}
                            automations={automations}
                            models={models}
                            setModels={setModels}
                            setAutomations={setAutomations}
                            tab={tab}
                            filterProject={filterProject}
                            filterDate={filterDate}
                            activeDay={activeDay}
                        />
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <div className="loader-container absolute">
            <CircularProgress size={30} />
        </div>
    );
};

export default TimeEntryList;
