import React from "react";
import CustomTag from "./CustomTag";

const CustomNavbarOption = ({ innerProps, isDisabled, label, subscriptions = [], ...props }) => {

    const customer = props.data;
    const isPremium = customer.supportInfo.isPremium;
    const isEssential = customer.supportInfo.isEssential;

    return (
        <>
            <div className="navbar-option-container">
                <div {...innerProps} className="custom-tag-container">
                    <div className="navbar-customer-icon">
                        <img
                            src={customer.logo ? customer.logo : "https://image.store.altelis.com/images/altelis-tools/logo-altelis.png"}
                            alt="navbar-logo"
                        />
                    </div>
                    <div>
                        <span className="navbar-title">{customer.name}</span>
                        <div className="navbar-tags">
                            <CustomTag value={isPremium ? "Premium" : (isEssential ? "Essentiel" : "Aucun")} className={`margin-left uppercase ${isPremium ? "premium" : (isEssential ? "essential" : "no-assistance")}`} />
                            <div className="conso-wrapper margin-left">
                                <CustomTag value={customer.supportHours + (customer.supportInfo.isPremium ? ` / ${ customer.supportInfo.premiumHours} h` : ' h')} className={ customer.supportInfo.isPremium ? (customer.supportHours > customer.supportInfo.premiumHours ? 'no-assistance' : 'success') : customer.supportHours > 0 ? 'no-assistance' : 'success'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="navbar-divider" />
        </>
    );
};

export default CustomNavbarOption;
